import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  surveyData: null,
};


var formData = new FormData();
formData.append("survey_request", "love_question1_option1");
formData.append("form_nonce", "48707551b5");
formData.append("key", "honey_pot_21585450");

const data = {
  survey_request: "love_question1_option1",
  form_nonce: "48707551b5",
  key: "honey_pot_21585450"
}



export const getNonce = createAsyncThunk(
  "survey/getNonce",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`form/security/get_nonce`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const getNonceSlice = createSlice({
  name: "survey",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getNonce.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getNonce.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.surveyData = action.payload?.data?.data;
    },
    [getNonce.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.surveyData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default getNonceSlice.reducer;
