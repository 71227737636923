import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Style from "./HomeContact.module.scss";
import Assets from "../../../Layouts/Assest";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDimensions } from "../../../../logic/useDimension";
import ScrollReveal from "../../../animations/ScrollReveal";
import { useDispatch } from "react-redux";
import { getNonce } from "../../../../store/slices/getNonceSlice";
import { contactData } from "../../../../store/slices/contactSlice";
const HomeContact = () => {
  gsap.registerPlugin(ScrollTrigger);
  const boxRef = useRef();
  const leaf1Ref = useRef();
  const leaf2Ref = useRef();
  const formRef = useRef();
  let contactRef = useRef(null);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    gsap.from(boxRef.current, {
      scrollTrigger: {
        trigger: boxRef.current,
        scrub: 1.5,
      },
      scale: 0.9,
      rotate: 20,
      ease: "none",
    });

    gsap.to(leaf1Ref.current, {
      scrollTrigger: {
        trigger: boxRef.current,
        scrub: 1.5,
      },
      y: -80,
      rotate: 30,
      ease: "none",
    });

    gsap.to(leaf2Ref.current, {
      scrollTrigger: {
        trigger: boxRef.current,
        scrub: 2.4,
      },
      y: -40,
      rotate: 45,
      ease: "none",
      delay: 0.8,
    });
  }, []);

  const { width } = useDimensions();
  const handleFocus = () => {
    const inputField = formRef.current.querySelectorAll(
      '.form-control[value=""]'
    );
    inputField[0]?.focus();
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    email: Yup.string().email(" Invalid email format ").required("Required "),
    phone: Yup.string()
      .required(" Required ")
      .matches(phoneRegExp, "Phone Number Is Not Valid")
      .min(9, "Phone Number Is Not Valid"),
    message: Yup.string().required(" Required "),
  });

  return (
    <>
      <section
        className={Style.gm_contact_home}
        ref={contactRef}
        id="contactSection"
      >
        <Container>
          <Row>
            <Col lg={7} className="position-relative">
              <ScrollReveal revealType={"reveal"} by="chars">
                <h3 className={`h3 ${Style.head_contact}`}>
                  <span className="head_lets">Let's</span> Make
                  <br /> It Happen
                </h3>
              </ScrollReveal>
              {width >= 992 ? (
                <div className={Style.contact_banner_wrap}>
                  <div className={Style.contact_leaf1} ref={leaf1Ref}>
                    <img src={Assets.leaf1} className="w-100" alt="" />
                  </div>
                  <div className={Style.contact_leaf2} ref={leaf2Ref}>
                    <img src={Assets.leaf2} className="w-100" alt="" />
                  </div>
                  <div className={Style.contact_banner} ref={boxRef}>
                    <picture>
                      <source
                        srcset={Assets.contact_banner_burger_webp}
                        type="image/webp"
                      />
                      <source
                        srcset={Assets.contact_banner_burger}
                        type="image/jpeg"
                      />
                      <img
                        src={Assets.contact_banner_burger}
                        alt={`greenmeat`}
                      />
                    </picture>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>

            <Col lg={5}>
              <h5 className={`h6 `} data-scroll="fade-in">
                Get in Touch with Us
              </h5>

              <Formik
                initialValues={{ email: "", name: "", phone: "", message: "" }}
                validationSchema={validationSchema}
                // validate={(values) => {
                //   const errors = {};
                //   if (!values.email) {
                //     errors.email = "Required";
                //   } else if (
                //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                //       values.email
                //     )
                //   ) {
                //     errors.email = "Invalid email address";
                //   }
                //   return errors;
                // }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    dispatch(getNonce())
                      .unwrap()
                      .then((value) => {
                        if (value && value && value.success) {
                          dispatch(
                            contactData({ value: value.data, data: values })
                          )
                            .unwrap()
                            .then((values) => {
                              setIsSuccess(values);
                              setIsSubmitted(!isSubmitted);
                              setSubmitting(false);
                              setTimeout(() => {
                                setIsSubmitted(false);
                                setIsSuccess(false);
                                resetForm();
                              }, 2000);
                            });
                        }
                      });
                  }, 400);
                }}
              >
                {({ isSubmitting }) => (
                  <Form ref={formRef}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Hello, I am"
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Hello, I am"
                      />
                      <ErrorMessage name="name">
                        {(msg) => (
                          <div
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Email">
                      <Field
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <div
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Phone No">
                      <Field
                        className="form-control"
                        type="text"
                        name="phone"
                        placeholder="Phone No"
                      />
                      <ErrorMessage name="phone">
                        {(msg) => (
                          <div
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Message">
                      <Field
                        name="message"
                        className="form-control"
                        placeholder="Message"
                        as="textarea"
                      />
                      <ErrorMessage name="message">
                        {(msg) => (
                          <div
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </FloatingLabel>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="button button-primary"
                      onClick={() => handleFocus()}
                    >
                      <span>
                        {" "}
                        {isSubmitting
                          ? `SUBMITTING..`
                          : isSuccess.success == true
                          ? `SUBMITTED`
                          : `SUBMIT`}{" "}
                        {isSubmitted ? (
                          isSuccess.success == true ? (
                            <span className={Style.success}></span>
                          ) : (
                            <span className={Style.failed}></span>
                          )
                        ) : null}
                        <i className="icon-arrow"></i>
                      </span>
                    </button>
                  </Form>
                )}
              </Formik>
              <div className="mt-3">
                <p>
                  Alternatively, email us at{" "}
                  <a href="mailto: hello@thegreenmeat.com">
                    hello@thegreenmeat.com
                  </a>{" "}
                  or whatsapp us at{" "}
                  <a href="https://wa.me/+91-99950 99114">+91-99950 99114</a>
                </p>
              </div>
              <div>
                <p className="mb-0">We are also available on:</p>
                <div className={`d-flex gap-4 mt-2 ${Style.contact_icon_wrap}`}>
                  <a href="https://www.amazon.in/stores/page/B02FCF84-5348-4120-8C81-3F2576E08643?ingress=2&visitId=206b4501-f8d0-406c-8616-7da50bfcff91&linkCode=sl2&tag=viramatt-21&linkId=b26cf487d83febfa2c1f7f6f27e1d154&language=en_IN&ref_=as_li_ss_tl" target="_blank" rel="noreferrer" className="">
                    <img
                      src={Assets.amazonIcon}
                      alt="amazon icon"
                    />
                  
                  </a>
                  <a href="https://www.mystore.in/en/seller/thegreenmeat
" target="_blank"  rel="noreferrer" className="">
                  <img              src="https://www.mystore.in/s/62ea2c599d1398fa16dbae0a//ms.files/ONDC-Network-200x60.png" alt="ONDC-Network"
                    />
                  </a>
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomeContact;
