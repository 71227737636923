import React, { useEffect, useState, useRef } from "react";
import Assets from "../Layouts/Assest";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Style from "./SurveyPopUp.module.scss";
import { useSurvey } from "../../logic/useSurvey";
import ThankYou from "../ThankYou";
import { useDispatch, useSelector } from "react-redux";
import { resetModalState } from "../../store/slices/surveySlice";

var attemps = 0;
const PopupCard = (props) => {
  const { surveyDetails, postSurvetDetails } = useSurvey();

  const inputObj = useRef(null);

  const [surveyModal, setSurveyModal] = useState(false);
  const [initialModal, setInitialModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [objectIndex, setObjectIndex] = useState("");
  const [catIndex, setCatIndex] = useState(0);
  const [data, setData] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [finalAnswers, setFinalAnswers] = useState([]);
  const [intrested, setIntrested] = useState(false);
  const [email, setEmail] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [randomData, setRandomData] = useState([]);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { isOpenModal } = useSelector((state) => state.survey);
  const dispatch = useDispatch()

  const surveyCategories = ["Love", "Your health", "Planet’s Health"];

  const getRandomObject = (array) => {
    console.log(randomData, "roandom..");
    let catData =
      surveyDetails &&
      surveyDetails?.data?.survey_qa?.filter(
        (survey) => survey.category == surveyCategories[catIndex]
      );
    let randomObject = [];
    if (catIndex == 0) {
      randomObject = catData[Math.floor(Math.random() * catData?.length)];
    } else {
      randomObject = array[Math.floor(Math.random() * array?.length)];
    }
    console.log("randomObject", randomObject);
    window.randomObject = randomObject;
    return randomObject;
  };

  // resetModalState



  function ValidateEmail(inputText) {

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var phoneformatlter = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    var passed = inputText.match(mailformat) || inputText.match(phoneformatlter);

    if (passed) {
      setEmail(inputText);
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
    }
  }

  const checkMail = function (email) {
    var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (filter.test(email)) {
      return true;
    }
    return false;
  }

  const checkPhone = function (phone) {
    var filter = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (filter.test(phone)) {
      return true;
    }
    return false;
  }


  useEffect(() => {
    if (!localStorage.getItem("survayStatus")) {
      localStorage.setItem("survayStatus", "initiated");
    }
    if (
      localStorage.getItem("survayStatus") !== "completed"
    ) {
      if (isOpenModal) {
        setInitialModal(true);
      }
      setData(surveyDetails?.data?.survey_qa);
    }
  }, [surveyDetails, isOpenModal]);

  useEffect(() => {
    if (data?.length > 0) {
      setRandomData(getRandomObject(data));
    }
  }, [data]);

  useEffect(() => {
    /******************************************************************************************
     * IF ATTEMPS HIT WITH THREE SURVAY STATUS WILL BE UPDATED AND WONT BE SHOWN SURAVY AGAIN *
     ******************************************************************************************/
    if (attemps === 3) {
      localStorage.setItem("survayStatus", "completed");
    }
  }, [attemps]);



  useEffect(() => {
    if (catIndex <= surveyCategories?.length - 1) {
      let catData =
        surveyDetails &&
        surveyDetails?.data?.survey_qa?.filter(
          (survey) => survey.category == surveyCategories[catIndex]
        );
      if (catData?.length > 0 && catIndex > 0) {
        setRandomData(catData[Math.floor(Math.random() * catData?.length)]);
      }
    }
  }, [catIndex]);


  useEffect(() => {

    if (confirmationModal === false && attemps == 3) {
      let arr = [
        ...finalAnswers,
        intrested,
        email,
        surveyDetails?.data?.key,
        surveyDetails?.data?.nonce,
      ];
      postSurvetDetails(arr, () => { });
    }

  }, [confirmationModal]);



  const handleClose = () => setSurveyModal(false);

  const handleStartSurvey = () => {
    setInitialModal(false);
    setSurveyModal(true);
    dispatch(resetModalState())
    setIntrested("true");
  };

  const handleEndSurvey = () => {
    localStorage.setItem("survayStatus", "rejected");
    setInitialModal(false);
    setConfirmationModal(true);
    dispatch(resetModalState())
    setIntrested("false");
  };

  const handleSelect = (e) => {
    attemps++;
    setSubmitted(true);
    setObjectIndex(
      randomData.options.findIndex((answer) => answer.title == e.target.title)
    );
    if (randomData.correctOption == e.target.title) {
      setCorrectAnswer(correctAnswer + 1);
    }
  };

  const handleNext = () => {
    if (catIndex === surveyCategories?.length - 1) {
      setSurveyModal(false);
      setTimeout(() => {
        setConfirmationModal(true);
      }, 100);
    }
    if (catIndex < surveyCategories?.length - 1) {
      setCatIndex(catIndex + 1);
      setSubmitted(false);
    }

    let finalArray = [...finalAnswers, randomData?.id, objectIndex];
    setFinalAnswers(finalArray);
  };

  const handlePost = () => {
    ValidateEmail(inputObj.current.value);

    var passed = checkMail(inputObj.current.value) === true || checkPhone(inputObj.current.value) === true

    if (passed) {
      let arr = [
        ...finalAnswers,
        intrested,
        inputObj.current.value,
        surveyDetails?.data?.key,
        surveyDetails?.data?.nonce,
      ];
      postSurvetDetails(arr, setEmailSubmitted);
    }
  };

  useEffect(() => {
    if (emailSubmitted === true) {
      setTimeout(() => {
        setConfirmationModal(false);
      }, 5000);
    }
  }, [emailSubmitted]);

  return (
    <>
      <Modal
        show={initialModal}
        centered
        onHide={() => handleEndSurvey()}
        className={Style.modal}
      >
        <Modal.Header>
          <div className={Style.cover}>
            <img src={Assets.cardImage01} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h3>
            Join the <span>Green Squad!</span>
          </h3>
          <p>Do you have a minute to answer 3 questions for us?</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="rounded-pill text-black"
            variant="outline-primary"
            onClick={() => handleStartSurvey()}
          >
            Yes
          </Button>
          <Button
            className="rounded-pill text-black"
            variant="outline-primary"
            onClick={() => handleEndSurvey()}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {/* confirmation modal */}
      <Modal
        show={confirmationModal}
        centered
        onHide={() => setConfirmationModal(false)}
        className={Style.modal}
      >
        <Modal.Header className={Style.modal_header} closeButton>
          {localStorage.getItem("survayStatus") == "rejected" && emailSubmitted == false && (<div className={Style.icon}>
            <img src={Assets.survay_skip_thank_you} />
          </div>)}
        </Modal.Header>
        <Modal.Body>
          <div className={Style.icon}></div>
          {attemps == 3 && emailSubmitted == false && <ThankYou message={true} />}
          {localStorage.getItem("survayStatus") == "rejected" && emailSubmitted == false && (
            <h4
              className={Style.category}
              dangerouslySetInnerHTML={{
                __html:
                  correctAnswer === surveyCategories?.length
                    ? "Thank you for being a champion. You are officially in our Green Squad"
                    : "<span>No worries.</span> Do come back when you’re ready for a rescue! See You!",
              }}
            ></h4>
          )}

          {emailSubmitted && (
            <>
              <ThankYou message={false} />
              <h4
                className={Style.category}
                dangerouslySetInnerHTML={{
                  __html: "Thank you! We will keep you posted",
                }}
              ></h4>
            </>
          )}
          <div className={`${Style.form_input_wrap} form-group`}>
            {emailSubmitted == false &&
              <>
                <input
                  className="form-control"
                  ref={inputObj}
                  placeholder={
                    "Enter your Email or Whatsapp number."
                  }
                // onChange={(e) => ValidateEmail(e.target.value)}
                />
                <button
                  onClick={() => {

                    handlePost();
                    //setConfirmationModal(false);
                  }}
                  className={Style.submit_btn}
                ></button>
              </>
            }
          </div>

          {errorMsg && (
            <p className={`text-danger mt-2 ${Style.text_danger}`}>
              Please enter a valid email or whatsapp number!
            </p>
          )}





        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* // <Button
          //   className="rounded-pill text-black"
          //   variant="outline-primary"
          //   onClick={() => {
          //     setConfirmationModal(false);
          //     handlePost();
          //   }}
          // >
          //   Continue
          // </Button> */}
        </Modal.Footer>
      </Modal>
      {/* questions modal */}
      <Modal
        show={surveyModal}
        centered
        onHide={handleClose}
        className={Style.modal}
      >
        {submitted ? (
          <>
            {randomData?.options[objectIndex].response.img != "" && (
              <Modal.Header>
                <div className={Style.cover}>
                  <img src={randomData?.options[objectIndex].response.img} />
                </div>
              </Modal.Header>
            )}
            <Modal.Body>
              <h4
                className={Style.category}
                dangerouslySetInnerHTML={{
                  __html: randomData?.options[objectIndex].response.title,
                }}
              ></h4>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                className="rounded-pill text-black"
                variant="outline-primary"
                onClick={() => handleNext()}
              >
                Next
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>

            {randomData?.img && (
              <Modal.Header>
                <div className={Style.cover}>
                  <img src={randomData?.img} />
                </div>
              </Modal.Header>
            )}
            <Modal.Body>
              <h3 dangerouslySetInnerHTML={{ __html: randomData?.title }}></h3>
              <h4 className={Style.category}>
                0{catIndex + 1}. {randomData?.category}
              </h4>
              <p>{randomData?.question}</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              {randomData?.options?.map((option, key) => {
                return (
                  <Button
                    title={option?.title}
                    className={`text-black rounded-pill ${option?.img !== "" && Style.image_btn} ${option?.img !== "" ? Style.btn_img : ""}`}
                    variant="outline-primary"
                    key={key}
                    onClick={(e) => {
                      handleSelect(e);
                    }}
                  >
                    {option?.img !== "" ? <img src={option?.img} /> : option?.title}
                  </Button>
                );
              })}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default PopupCard;
