import React, { useEffect } from "react";
import Style from "./Cursor.module.scss";
import { gsap } from "gsap";
const Cursor = () => {
  const cursor = document.getElementsByClassName(Style.cursor);
  const videoWrapper = document.querySelector(`[data-cursor="video"]`);
  const dragWrapper = document.querySelector(`[data-cursor="drag"]`);
  const navWrapper = document.querySelector(`[data-cursor="nav"]`);
  const darkenWrapper = document.querySelector(`[data-cursor="darken"]`);

  useEffect(() => {

    gsap.set([cursor], { xPercent: -50, yPercent: -50 });

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.35;

    const xSet = gsap.quickSetter(cursor, "x", "px");
    const ySet = gsap.quickSetter(cursor, "y", "px");

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
    });

    gsap.ticker.add(() => {
      const dt = 0.8 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
  }, []);
  useEffect(() => {
    videoWrapper?.addEventListener("mouseenter", function () {
      cursor[0].classList.add(Style.cursor_video);
    });
    videoWrapper?.addEventListener("mouseleave", function () {
      cursor[0].classList.remove(Style.cursor_video);
    });
    dragWrapper?.addEventListener("mouseenter", function () {
      cursor[0].classList.add(Style.cursor_drag);
    });
    dragWrapper?.addEventListener("mouseleave", function () {
      cursor[0].classList.remove(Style.cursor_drag);
    });
    // navWrapper?.addEventListener("mouseenter", function () {
    //   cursor[0].classList.add(Style.cursor_nav);
    // });
    // navWrapper?.addEventListener("mouseleave", function () {
    //   cursor[0].classList.remove(Style.cursor_nav);
    // });
    darkenWrapper?.addEventListener("mouseenter", function () {
      cursor[0].classList.add(Style.cursor_darken);
    });
    darkenWrapper?.addEventListener("mouseleave", function () {
      cursor[0].classList.remove(Style.cursor_darken);
    });
  }, [darkenWrapper, videoWrapper, navWrapper, dragWrapper, window.location.pathname]);
  return (
    <>
      <div className={Style.cursor}></div>
    </>
  );
};
export default Cursor;
