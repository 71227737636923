import React, { lazy } from 'react';
import Preloader from '../../Preloader';
const Menu = lazy(() => import("../Menu"))
const Footer = lazy(() => import("../Footer"))

const CommonLayout = ({ children }) => {
    return (
        <>
            {/* <Preloader/> */}
            <Menu />
            <div className='MainWrapper'>
                <div className='MainWrapperInner'>
                    {children}
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CommonLayout;