import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  contactData: null
};







export const contactData = createAsyncThunk(
  "contact/contactData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    var formData = new FormData();
    formData.append("your_name", params.data.name);
    formData.append("your_email", params.data.email);
    formData.append("your_phone", params.data.phone);
    formData.append("your_message", params.data.message);
    formData.append("form_nonce", params.value.nonce);
    formData.append("key", params.value.key);



    try {
      const response = await api.post(`form/action/get-in-touch-with-us`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const surveySlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateModalState: (state, action) => {
      state.isOpenModal = action.payload
    }
  },
  extraReducers: {
    [contactData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [contactData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.contactData = action.payload?.data?.data;
    },
    [contactData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.contactData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});
export const {
  updateModalState
} = surveySlice.actions
export default surveySlice.reducer;
