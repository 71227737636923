import React, { useEffect } from "react";
import { useSurvey } from "../../logic/useSurvey";
import PopupCard from "./popupCard";
import Style from "./SurveyPopUp.module.scss";
import { updateModalState } from "../../store/slices/surveySlice";
import { useSelector, useDispatch } from "react-redux";

const SurveyPopUp = () => {
  //   const dispatch = useDispatch();
  //   const { isOpenModal } = useSelector((state) => state.survey);
  //   const isModelOpen = localStorage.getItem("isModelShow");
  //   useEffect(() => {
  //     setTimeout(() => {
  //       !isModelOpen && dispatch(updateModalState(true));
  //       !isModelOpen &&
  //         document.querySelector("html").classList.add("lock-scroll");
  //     }, 5000);
  //   }, []);
  //   const checkModel = isOpenModal ? Style.open : "";
  //   console.log("isopen", isOpenModal);

  return (
    <>
      {/* <div className={`${Style.popupMask} ${checkModel}`}></div> */}
      <PopupCard />
    </>
  );
};

export default SurveyPopUp;
