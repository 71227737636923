import React from "react";

const Shipping = () => {
  return (
    <main className="terms">
      <section>
        <div className="container">
          <h1 className="h4">Shipping and Exchange Policy</h1>
          <div className="content-area">
            <h2 className="h6">Shipping Policy</h2>
            <ol>
              <li>
                <b>GENERAL</b>
                <p>
                  This website with the URL of <a href="https://www.thegreenmeat.com" target="_blank" rel="noopener noreferrer">www.thegreenmeat.com</a> ("Website")
                  is operated by Greenovative Foods Pvt Ltd ("We/Us/Our").
                </p>
                <ul>
                  <li>
                    <p>
                      We are committed to delivering the order placed by You
                      within the given period and in accordance with this
                      Shipping Policy ("Policy").
                    </p>
                  </li>
                  <li>
                    <p>
                      We do ship/deliver the products across India subject to
                      the provisions of this Policy. You are advised to read Our
                      Terms and Conditions along with this Policy.
                    </p>
                  </li>
                  <li>
                    <p>
                      By using this website, you agree to be bound by the terms
                      contained in this Policy without modification. If you do
                      not agree to the terms contained in this Policy, You are
                      advised not to transact on this website.
                    </p>
                  </li>
                  <li>
                    <p>
                      The following currency is used on this Website: INDIAN
                      RUPEE.
                    </p>
                  </li>
                  <li>
                    <p>
                      All orders are subject to product availability. If an item
                      is not in stock at the time you place your order, We will
                      notify you and refund the total amount using the original
                      method of payment.
                    </p>
                  </li>
                  <li>
                    <p>
                      We make all commercially reasonable endeavors to ensure
                      that the products are delivered to you in a timely manner.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>DEFINITIONS</b>
                <ul>
                  <li>
                    <p>
                      "Customer" means a person who buys any goods for
                      consideration but does not include a person who purchases
                      such goods with the purpose of reselling them.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>COST OF SHIPPING</b>
                <ul>
                  <li>
                    <p>
                      Shipping charges are applied and informed at the checkout
                      window of the website along with the total price of the
                      goods at the time You carry out the online purchase from
                      this Website.
                    </p>
                  </li>
                  <li>
                    The aforementioned cost of shipping is applicable for
                    delivery of products under this Website regardless of the
                    value and delivery destination.
                  </li>
                </ul>
              </li>
              <li>
                <b>SHIPPING UPDATES</b>
                <ul>
                  <li>
                    <p>
                      Once your order ships, you will get a notification with
                      the details of the courier service provider and other
                      relevant information through the following method: SMS,
                      Email.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>HANDLING TIME</b>
                <ul>
                  <li>
                    <p>
                      We strive to process and ship you products at our
                      earliest.
                    </p>
                  </li>
                  <li>
                    <p>
                      However, it usually takes 2 (two) days to process and ship
                      the products once the order is placed with us.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>TIME FOR SHIPPING</b>
                <ul>
                  <li>
                    <p>
                      Delivery time depends on the products ordered and the
                      location of delivery. Approximate date of delivery will be
                      notified to you after you place the order.
                    </p>
                  </li>
                  <li>
                    <p>
                      The delivery date is indicative and can vary due to
                      external factors.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>INTERNATIONAL SHIPPING</b>
                <ul>
                  <li>
                    <p>
                      Currently, We do not deliver to locations outside India.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>OTHER RULES</b>
                <ul>
                  <li>
                    <p>
                      The shipping address cannot be changed once the order has
                      been placed on the Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      While We shall strive to ship all your ordered products
                      together, this may not always be possible due to product
                      characteristics, or availability.
                    </p>
                  </li>
                  <li>
                    <p>Partial refunds will not be entertained.</p>
                  </li>
                  <li>
                    <p>
                      We endeavour to engage logistic partners, employees,
                      agents with the highest standards and ethics, you agree
                      and acknowledge that the actions, inaction of the delivery
                      individuals are not in our control, and it is not possible
                      for us to monitor and observe each delivery executive and
                      We shall not be liable for any action or inaction from the
                      part of logistic partners. Any disputes between You and
                      logistics partners shall be settled between you and the
                      logistics partner.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>RESTRICTIONS</b>
                <ul>
                  <li>
                    <p>
                      Some items may not be eligible to be delivered to some
                      geographical locations. You will be notified of the
                      restricted items at the check-out page or before that.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>WRONG OR INCOMPLETE ADDRESS</b>
                <ul>
                  <li>
                    <p>
                      You shall ensure that all information that is submitted by
                      You to us on the platform is true, complete, accurate and
                      sufficient to identify the actual place of delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      If You have entered the wrong shipping information or
                      contact details, it might cause a delay or missed delivery
                      and you will not be eligible for a reshipment or a refund
                      on such a transaction.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>RETURN AND REFUND</b>
                <ul>
                  <li>
                    <p>
                      Every effort is made so as to service the orders placed,
                      as per the specifications and timelines mentioned with
                      respect to a product. If due to any unforeseen
                      circumstances or limitations from Our side, the order is
                      not shipped or delivered then such order stands cancelled,
                      and the amount paid by You shall be refunded.
                    </p>
                  </li>
                  <li>
                    <p>
                      The return process of a product may be subject to
                      additional terms depending on the nature and category of
                      the product. Any such additional terms may be specified
                      under the Return and Refund Policy or be intimated at the
                      time of purchase of the product.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>YOUR DATA</b>
                <ul>
                  <li>
                    <p>
                      The privacy of your data supplied to Us during the
                      shipping procedure is also governed by our privacy policy.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>CHANGES TO THIS POLICY</b>
                <ul>
                  <li>
                    <p>
                      Please note that We may from time to time change the terms
                      of this Policy and every time You wish to use this
                      website, please check the Policy to ensure You understand
                      the terms and conditions that apply at that time.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you do not wish to accept the revised Policy, you
                      should not continue to use the Services. If you continue
                      to use the Services after the date on which the changes,
                      come into effect, Your use of the Services indicates your
                      agreement to be bound by the new Policy.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <b>FORCE MAJEURE</b>
                <p>
                  We shall not be considered in breach of guarantee or terms of
                  service and shall not be liable to the Customer for any
                  cessation, interruption, or delay in the performance of its
                  obligations by reason beyond Our control including natural
                  disasters, pandemics, fire, an act of God or public enemy,
                  famine, plague, the action of the court or public authority,
                  change in law, explosion, war, terrorism, armed conflict,
                  labour strike, lockout, boycott or similar event beyond our
                  reasonable control, whether foreseen or unforeseen.
                </p>
              </li>
              <li>
                <b>CONTACT US</b>
                <p>
                  For any feedback, concern, or query, you may please reach out
                  to us on the contact details below:
                </p>
                <p>
                  Customer Care:{" "}
                  <a
                    href="https://care@thegreenmeat.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    care@thegreenmeat.com
                  </a>
                </p>
              </li>
            </ol>
            <h2 className="h6">Exchange Policy</h2>
            <p>
              As we are dealing with food products, we don’t provide exchange of
              our products.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Shipping;
