import React, { lazy, Suspense, useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import { useDimensions } from "../logic/useDimension";
import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../styles/common.scss";
import Cursor from "./Cursor";
import ScrollToTop from "./ScrollToTop";
import Assets from "./Layouts/Assest";
import SurveyPopUp from "./SurveyPopUp";
import PopupCard from "./SurveyPopUp/popupCard";
import LoaderContext from "../logic/useContext";
import CancellationPage from "../pages/Cancellation";
import ShippingPage from "../pages/Shipping";
import ContactPage from "../pages/ContactPage";
const HomePage = lazy(() => import("../pages/Home"));
const PromisePage = lazy(() => import("../pages/Promise"));
const RecipesPage = lazy(() => import("../pages/Recipes"));
const RecipesDetailPage = lazy(() => import("../pages/RecipeDetail"));
const WhoWeArePage = lazy(() => import("../pages/WhoWeAre"));
const FoodForPage = lazy(() => import("../pages/FoodFor"));
const SpotlightPage = lazy(() => import("../pages/Spotlight"));
const SpotlightDetailPage = lazy(() => import("../pages/SpotlightDetail"));
const ProductPage = lazy(() => import("../pages/productPage"));
const TermsPage = lazy(() => import("../pages/Terms"));
const PrivacyPage = lazy(() => import("../pages/Privacy"));
const ErrorPage = lazy(() => import("../pages/404Page"));

const App = () => {
  gsap.registerPlugin(ScrollTrigger);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      gsap.set(".homeBanner > .container", { y: "50vh", position: "relative" });
    }, 100);
  }, [document]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    if (!loading) {
      gsap.to(".loadingWrapper", {
        y: "-101%",
        duration: 1.3,
        ease: Expo.easeInOut,
      });
      gsap.to(".homeBanner > .container", {
        y: 0,
        duration: 1.3,
        ease: Expo.easeInOut,
      });
      gsap.to(".roundedMain", {
        height: `0vh`,
        duration: 1.3,
        ease: Expo.easeInOut,
      });
    }
  }, [loading]);

  const { width } = useDimensions();

  window.addEventListener("load", (event) => {
    setTimeout(() => {
      ScrollTrigger.refresh();
      setLoading(false);
    }, 2000);
  });

  return (
    <>
      {matchMedia("(hover: none)").matches ? "" : <Cursor />}
      <LoaderContext.Provider value={{ loading }}>
        <Router>
          <div className={"loadingWrapper"}>
            {" "}
            <div className={`roundedMain`}>
              <div className={`roundedWrapper`}></div>
            </div>
            <div className={"loadingInner"}>
              <div className={"loadingCircle"}>
                <img
                  src={Assets.loader}
                  style={{ width: "80px" }}
                  alt="logo loading"
                />
              </div>
            </div>
          </div>
          <div className="loader">
            <div className="img">
              {/* <img src={Assets.loader} style={{width: '80px'}} alt="logo loading" /> */}
            </div>
            <div className="loader__element"></div>
          </div>
          <Suspense fallback={null}>
            {/* <ScrollToTop /> */}
            <PopupCard />
            <Switch>
              <Route exact path="/" component={HomePage}></Route>
              <Route path="/promise" component={PromisePage}></Route>
              <Route path="/recipe" exact component={RecipesPage}></Route>
              <Route
                exact
                path="/recipe/:slug"
                component={RecipesDetailPage}
              ></Route>
              <Route path="/who-we-are" component={WhoWeArePage}></Route>
              <Route path="/foodfor" component={FoodForPage}></Route>
              <Route path="/spotlight" exact component={SpotlightPage}></Route>
              <Route path="/product" exact component={ProductPage}></Route>
              <Route
                path="/spotlight/:slug"
                component={SpotlightDetailPage}
              ></Route>
              <Route path="/contact" exact component={ContactPage}></Route>
              <Route path="/terms" exact component={TermsPage}></Route>
              <Route path="/privacy" exact component={PrivacyPage}></Route>
              <Route path="/cancellation" exact component={CancellationPage}></Route>
              <Route path="/shipping" exact component={ShippingPage}></Route>
              <Route path="*" exact component={ErrorPage}></Route>
            </Switch>
          </Suspense>
        </Router>
      </LoaderContext.Provider>
    </>
  );
};

export default App;
