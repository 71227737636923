import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  surveyData: null,
  surveyDetails: null,
  isOpenModal: false,
};

export const getSurveyData = createAsyncThunk(
  "survey/getSurveyData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    var formData = new FormData();
    console.log(params);
    formData.append("survey_request", params.slug);
    formData.append("form_nonce", params.value.nonce);
    formData.append("key", params.value.key);

    try {
      const response = await api.post(`form/survey/submit`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const postSurveyData = createAsyncThunk(
  "survey/getSurveyData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    var formData = new FormData();
    console.log(params, "paramss..");
    if (params.length != 4) {
      formData.append("q1", params[0]);
      formData.append("q2", params[2]);
      formData.append("q3", params[4]);
      formData.append("q1_answer", params[1] == 0 ? "yes" : "no");
      formData.append(
        "q2_answer",
        params[3] == 0 ? "Animal-based Food" : "Plant-based Food"
      );
      formData.append(
        "q3_answer",
        params[5] == 0 ? "Animal-based Food" : "Plant-based Food"
      );
      formData.append("intrested", "true");
      formData.append("email", params[7]);
      formData.append("form_nonce", params[9]);
      formData.append("key", params[8]);
    } else {
      formData.append("intrested", "false");
      formData.append("email", params[1]);
      formData.append("key", params[2]);
      formData.append("form_nonce", params[3]);
    }
    try {
      const response = await api.post(`survey/insert_survey_details`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSurveyDetails = createAsyncThunk(
  "survey/getSurveyDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`survey/get_survey_details`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    updateModalState: (state, action) => {
      state.isOpenModal = action.payload;
    },
    resetModalState: (state, action) => {
      state.isOpenModal = false;
    },
  },
  extraReducers: {
    [getSurveyData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSurveyData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.surveyData = action.payload?.data?.data;
    },
    [getSurveyData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.surveyData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    [postSurveyData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [postSurveyData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.surveyData = action.payload?.data?.data;
    },
    [postSurveyData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.surveyData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

    [getSurveyDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSurveyDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.surveyDetails = action.payload?.data;
    },
    [getSurveyDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.surveyDetails = action.payload?.data;
    },
  },
});
export const { updateModalState, resetModalState } = surveySlice.actions;
export default surveySlice.reducer;
