import React from "react";

const Cancellation = () => {
  return (
    <main className="terms">
      <section>
        <div className="container">
          <h1 className="h4">Cancellation and Refund Policy</h1>
          <div className="content-area">
            <p>
              As this is a food product, we do not offer returns. However,
              complete customer satisfaction is a top most priority for us. So,
              if you make a request with all the details, we shall investigate
              the matter and shall refund the money back, if we find that your
              reasons are genuine and proven.
            </p>
            <p>
              Our policy for the cancellation and refund will be as follows:
            </p>
            <h2 className="h6">Cancellation Policy</h2>
            <p>
              Requests for cancellation received after the product has been
              shipped won't be accepted.
            </p>
            <h2 className="h6">Refund Policy</h2>
            <p>
              As we are dealing with food products, we don’t provide a refund if
              the product is opened and used. If there is a breakage or damage
              to the product while transportation, necessary refunds will be
              provided upon investigation on submitting the photos.
            </p>
            <p>
              Refunds will be issued to the original payment source (credit
              card, bank account, etc) within 7 working days.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Cancellation;
