import React from "react";
import Assets from "../Layouts/Assest";
import Styles from "./ThankYou.module.scss";

const ThankYou = ({ message }) => {
  return (
    <>
      <div className={Styles.thank_you}>
        <div className={Styles.thank_you_icon}>
          <img src={Assets.thank_you_icon} alt="thank_you_icon" />
        </div>
        <div className={Styles.thank_you_text}>
          {message && (
            <p>
              <span>Thank you</span> for being a champion. <br />
              You are officially in our Green Squad
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ThankYou;
