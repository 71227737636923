import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

const ScrollReveal = ({
  children,
  duration = 1,
  revealType,
  className,
  by,
}) => {
  gsap.registerPlugin(ScrollTrigger);
  let ScrollRef = useRef(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     Array.from(document.querySelectorAll("div.is-inview")).forEach(function (
  //       el
  //     ) {
  //       el.classList.remove("is-inview");
  //     });
  //   }, 300);
  // }, []);
  useEffect(() => {
    ScrollTrigger.refresh();
    if (by) {
      Splitting({
        target: ScrollRef.current,
        by: by,
        key: null,
      });
    }
    const elem = ScrollRef.current;
    gsap.from(elem, 1.5, {
      scrollTrigger: {
        trigger: elem,
        // onEnter: () => elem.classList.add("is-inview"),
        once:true,
        toggleClass: "is-inview"
      },
    });
  }, [className, revealType, by]);
  return (
    <div
      ref={ScrollRef}
      className={className}
      data-scroll={revealType}
      data-split={by}
    >
      {children}
    </div>
  );
};

export default ScrollReveal;
