import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNonce } from "../store/slices/getNonceSlice";

import {
  getSurveyData,
  updateModalState,
  getSurveyDetails,
  postSurveyData,
} from "../store/slices/surveySlice";

export const useSurvey = (props) => {
  const dispatch = useDispatch();
  const [isOPen, setIsOPen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState([props && props[0]]);
  const [currentScreenProp, setCurrentScreenProp] = useState(1);
  const [surveyDatas, setSurveyDatas] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkbox, setCheckbox] = useState(1);
  const { isOpenModal } = useSelector((state) => state.survey);

  useEffect(() => {
    dispatch(getSurveyDetails());
    if (props) {
      setSurveyDatas(props);
    }
  }, []);

  useEffect(() => {
    const filterEvenResults =
      surveyDatas &&
      currentScreenProp &&
      surveyDatas.filter(
        (person) => person.screenNumber === currentScreenProp.screen
      );
    filterEvenResults && setCurrentScreen(filterEvenResults);
    filterEvenResults &&
      currentScreenProp.text !== "" &&
      getSurveysData(currentScreenProp.text);
    filterEvenResults &&
      currentScreenProp.hidePopUp &&
      dispatch(updateModalState(false));
    filterEvenResults &&
      currentScreenProp.hidePopUp &&
      localStorage.setItem("isModelShow", false);
    filterEvenResults &&
      currentScreenProp.hidePopUp &&
      document.querySelector("html").classList.remove("lock-scroll");

    // if (currentScreenProp && currentScreenProp.screen === 21) {
    //     console.log('okay');
    //     setTimeout(() => {
    //         dispatch(updateModalState(false))
    //         localStorage.setItem('isModelShow', false);
    //         document.querySelector('html').classList.remove('lock-scroll');
    //     }, 2000);
    // }
  }, [currentScreenProp]);

  useEffect(() => {
    if (isOpenModal) {
      setTimeout(() => {
        setIsSubmitting(true);
      }, 100);
    }
  }, [isOpenModal, isSubmitting]);

  const { status, surveyDetails } = useSelector((state) => state.survey);
  const postSurvetDetails = (id,setEmailSubmitted) => {
    dispatch(postSurveyData(id)).then((data)=>{
      if(data.payload.data.success === true){
        setEmailSubmitted(true);
      }
    });
  };

  const getSurveysData = (props) => {
    dispatch(getNonce())
      .unwrap()
      .then((value) => {
        if (value && value && value.success) {
          dispatch(getSurveyData({ value: value.data, slug: props }));
        }
      });
  };
  return {
    getSurveysData,
    status,
    isOPen,
    currentScreen,
    surveyDatas,
    status,
    setCurrentScreenProp,
    setIsSubmitting,
    isSubmitting,
    checkbox,
    setCheckbox,
    surveyDetails,
    postSurvetDetails,
  };
};
