import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Contact from "../../components/Contact";
import Helmet from "react-helmet";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        ‍<title>Contact Us | Green Meat</title>‍
        <meta
          name="description"
          content="The Greenmeat is a 100% plant based meat substitute, that is Surprisingly Good! It is preservative free, protein rich, and sustainable. Buy Now."
        />
      </Helmet>

      <CommonLayout>
        <Contact />
      </CommonLayout>
    </>
  );
};

export default ContactPage;
