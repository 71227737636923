import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecipeAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  blogData: [],
  singleBlog: null,
  selectedCategoryItem: "all",
};

export const getBlogData = createAsyncThunk(
  "recipes/get",
  async (params, { rejectWithValue }) => {
    const api = await getRecipeAxiosInstance();
    try {
      const response = await api.get(`recipe`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBlogCategoryData = createAsyncThunk(
  "blog/getBlogCategoryData",
  async (params, { rejectWithValue }) => {
    const api = await getRecipeAxiosInstance();
    try {
      const response = await api.get(`blog/categories`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleBlogData = createAsyncThunk(
  "recipe/getSingleRecipeData",
  async (params, { rejectWithValue }) => {
    const api = await getRecipeAxiosInstance();
    try {
      const response = await api.get(`recipe/single?pid=${params.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFilteredBlogData = createAsyncThunk(
  "blog/getFilteredBlogData",
  async (params, { rejectWithValue }) => {
    const api = await getRecipeAxiosInstance();
    try {
      const response = await api.get(
        `blog?page=${"1"}&posts_per_page=4&cat=${params}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    currentSelectedCategory: (state, action) => {
      state.selectedCategoryItem = action.payload;
    },
  },

  extraReducers: {
    [getBlogData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBlogData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogData = action.payload?.data;
    },
    [getBlogData.rejected]: (state, action) => {
      state.status = "failed";
      // if (action.payload?.data.length !== 0) {
      //   state.blogData = action.payload?.data;
      // }
      state.message = action.payload?.message;
    },

    [getFilteredBlogData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFilteredBlogData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogData[state.selectedCategoryItem] = action.payload?.data?.data;
    },
    [getFilteredBlogData.rejected]: (state, action) => {
      state.status = "failed";

      // if (action.payload?.data.length !== 0) {
      //   state.blogData = action.payload?.data;
      // }
      state.message = action.payload?.message;
    },
  },
});

export const { currentSelectedCategory } = blogSlice.actions;

export default blogSlice.reducer;
