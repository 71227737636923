import React from 'react';
import Helmet from 'react-helmet';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Shipping from '../../components/Shipping';

const ShippingPage = () => {
    return (
        <>
            <Helmet>‍
                <title>Shipping and Exchange Policy | Greenmeat</title>‍
                <meta name="description" content="Shipping and exchange policy" />
            </Helmet>
            <CommonLayout>
                <Shipping />
            </CommonLayout>
        </>
    )
}

export default ShippingPage;