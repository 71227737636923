import React from 'react';
import Helmet from 'react-helmet';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Cancellation from '../../components/Cancellation';

const CancellationPage = () => {
    return (
        <>
            <Helmet>‍
                <title>Cancellation and Refund | Greenmeat</title>‍
                <meta name="description" content="Cancellation and refund " />
            </Helmet>
            <CommonLayout>
                <Cancellation />
            </CommonLayout>
        </>
    )
}

export default CancellationPage;