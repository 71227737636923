import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Style from "./ScrollToTop.module.scss";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }, 20);
      
    });

    return () => {
      unlisten();
    };
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);
