import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  homeData: null,
  redirectionTempValue:""
};

export const getHomeData = createAsyncThunk(
  "home/getHomeData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
    //   const response = await api.get(``);
    //   return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setRedirectionTempValue: (state, action) => {
      state.redirectionTempValue = action.payload;
    },
  },
  extraReducers: {
    [getHomeData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getHomeData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.activityData = action.payload?.data?.data;
    },
    [getHomeData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.courseData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});
export const { setRedirectionTempValue } = homeSlice.actions;

export default homeSlice.reducer;
