import { combineReducers } from "@reduxjs/toolkit";

import homeSlice from "./slices/homeSlice";
import blogSlice from "./slices/blogSlice"
import surveySlice from "./slices/surveySlice";
import recipeSlice from "./slices/recipeSlice";


const rootReducer = combineReducers({
  home: homeSlice,
  blog : blogSlice,
  recipe : recipeSlice,
  survey: surveySlice,
});

export default rootReducer;